<template>
    <v-container fluid fill-height>
      <div class="d-flex justify-center align-center flex-column" style="width: 100%">
        <h1 class="text-center display-4 font-weight-bold primary--text">
          404
        </h1>
        <h3 class="text-center primary--text text--darken-1">
          Sorry, we could not find the page you where looking for...
        </h3>
      </div>
    </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>